import 'minireset.css/minireset.css'
import { createGlobalStyle} from "styled-components"
import 'modern-normalize/modern-normalize.css'


export const GlobalStyles = createGlobalStyle`
  html {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-family: inter, Helvetica, Arial, Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.2px;
    // transition: all 0.50s;
  }
  ::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: #303338;
  }
  
  ::selection {
    color: #fff;
    background: #303338;
  }
  .page-content{
    max-width: 1298px;
    margin: 8px auto 48px auto;
  }
  // portfolio details
  .grid-background{
    background-image: linear-gradient(${props => props.theme.colors.grid} 1px, transparent 1px), linear-gradient(90deg, ${props => props.theme.colors.grid} 1px, transparent 1px);
    background-size: 100% 32px, 32px 100%;
    border: solid 1px ${props => props.theme.colors.grid};

    @media (min-width: 740px) {
      background-size: 100% 48px, 48px 100%;
    }
  }
  .no-background {
    background: ${props => props.theme.colors.background};  
  }
  .project-hero-image {
    background: linear-gradient(180deg, transparent 50%,${props => props.theme.colors.background} 50%);
  }

`

