const Xs = '500px'
const Sm = '740px'
const Md = '900px'
const Lg = '1080px'
const Xl = '1200px'
const Xxl = '1540px'

const defaultTheme = {
  brandFont: 'inter',
  breakpoints: [Sm, Md, Lg, Xl, Xxl],
  space: [
    0, 4, 8, 16, 24, 32, 48, 56, 64, 96, 128, 256
  ],
}

const breakpoints = [
  { xs: Xs },
  { sm: Sm },
  { md: Md },
  { lg: Lg },
  { xl: Xl },
  { xxl: Xxl },
]

export const Mq = breakpoints.reduce((acc, breakpoint) => {
  const entry = Object.entries(breakpoint)[0]
  acc = { ...acc, [entry[0]]: `@media (min-width: ${entry[1]})` }
  return acc
}, {})


const dark = {
  colors: {
    logo: 'rgba(215, 151, 197 , 0.80)',
    highlightColor: '#ECF46C',
    colorText: 'rgba(215, 151, 197, 0.80)',
    grayText: '#AFB3B9',
    text: '#E8EAED',
    grid: 'rgba(215, 151, 197, 0.20)',
    gridHover: 'rgba(215, 151, 197, 0.80)',
    background: '#202124',
    backgroundHover: '#232024',
    shadow: '#303338',
  },
}

const light = {
  colors: {
    logo: '#97D7A1',
    highlightColor: '#67CC77',
    colorText: '#44BE57',
    grayText: '#928B90',
    text: '#202124',
    grid: 'rgba(151, 215, 161, 0.30)',
    gridHover: 'rgba(151, 215, 161, 1)',
    background: '#F2F2F2',
    backgroundHover: '#ECEFEC',
    shadow: '#D3D9D4',
  },
}

export const lightTheme = { ...defaultTheme, ...light }
export const darkTheme = { ...defaultTheme, ...dark }