import styled from "styled-components";
import { Mq } from '../styles/settings/theme';

const mdFontSize = props => {
  if (props.largeText) return '1.1875rem';
  if (props.smallText) return '16px';

  return '1.0625rem';
};

const mdLineHeight = props => {
  if (props.largeText) return '1.9375rem';
  if (props.smallText) return '1.5625rem';

  return '175%';
};

export const Paragraph = styled.p`
  margin-bottom: ${props => (props.noMargin ? '0' : '1rem')};
  font-size: ${props => (props.largeText ? '1.0625rem' : '16px')};
  line-height: ${props => (props.largeText ? '1.6875rem' : '1.5625rem')};

  ${Mq.md} {
    font-size: ${mdFontSize};
    line-height: ${mdLineHeight};
  }
`;

export const H1 = styled.h1`
  font-size: 1.8rem;

  ${Mq.md} {
    font-size: 2.5rem;
  }
`

export const H2 = styled.h2`
  font-size: 1.75rem;

  ${Mq.md} {
    font-size: 2rem;
  }
`

export const H3 = styled.h3`
  font-size: 1.3rem;
  
  ${Mq.md} {
    font-size: 1.75rem;
  }
`

export const H4 = styled.h4`
  font-size: 1rem;
  font-weight: 600;

  ${Mq.md} {
    font-size: 1.2rem;
  }
`

export const OL = styled.ol`
  margin-left: 18px;
`

export const UL = styled.ul`

`

export const LI = styled.li`
  font-size: 16px;
  margin-bottom: 10px;
`

export const Gray = styled.span`
  color: ${props => props.theme.colors.grayText};
`