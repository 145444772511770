import React from "react";
import styled from "styled-components";
import { Box, FlexProps, Flex } from "@rebass/emotion";
import { Link } from "react-router-dom";

const Nav = styled(Flex)<FlexProps>`
  max-width: 1320px;
  margin: 0 auto;
  height: 48px;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.colors.background};
  margin-bottom: 8px;
  z-index: 999;
  overflow: hidden;

  .section {
      width: 33.33%;
      display: flex;
  }
  .section.center {
      width: 20%;
      justify-content: center;
      text-align: center;
      @media (min-width: 560px) {
        width: 33.33%;
      }
  }
  .section.right {
      justify-content: right;
  }
  a {
    padding: 16px 8px;
    text-decoration: none;
    color: ${props => props.theme.colors.text};
    transition: 0.2s ease all;

    &:hover {
      color: ${props => props.theme.colors.highlightColor};
    }
    @media (min-width: 560px) {
      padding: 16px;
    }
  }

  .linkedin {
    display: none;
    @media (min-width: 420px) {
      display: block;
    }
  }

  .toggleLink {
    padding: 8px 16px;
  }

  #theme-toggle {
    padding: 4px 3px 3px 3px;
    border-radius: 50%;
    background: ${props => props.theme.colors.shadow};
    border: solid 1px transparent;
    cursor: pointer;
    width: 40px;
    height: 40px;
    span {
      font-size: 26px;
    }
    &:hover {
      background: ${props => props.theme.colors.backgroundHover};
      border-color:${props => props.theme.colors.gridHover}
    }
  }
`;

const NavLink = styled(Link)`
  padding: 16px;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  transition: 0.2s ease all;
  
  &:hover {
    color: ${props => props.theme.colors.highlightColor};
  }
  
  &.logo {
    background-color: ${props => props.theme.colors.logo};
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 16px;
    width: 48px;
    transition: 0.2s all ease;
    &:hover{
        text-decoration: none;
        color: #fff;
    }
  }
`;


export function Navigation(props) {
  return (
    <Nav>
        <div className="left section">
            {/* <NavLink to="/">Work</NavLink> */}
            {/* <NavLink to="/about">About</NavLink> */}
        </div>
        <div className="center section">
            <NavLink className="logo" to="/">
                M
            </NavLink>
        </div>
        <div className="right section">
          <a className="linkedin" rel="noopener noreferrer" href="https://www.linkedin.com/in/mairaahmad/" target="_blank">Linkedin</a>
          <div className="toggleLink">{props.toggleButton}</div>
        </div>
    </Nav>
  );
}
