import React from "react";
import styled from "styled-components";
import { Image, Box, BoxProps, FlexProps, Flex } from "@rebass/emotion";
import { Link } from "react-router-dom";

import mairaAhmad from "../images/mairaAhmad.jpg";

import { Paragraph, H1, H2, H4 } from "../atoms/text";
import { ButtonLinkComp } from "../atoms/button";

// ---------
const ContentBackground = styled(Box)`
    background-color: ${props => props.theme.colors.background};
    margin: 97px 32px 97px 33px;
    position: relative;

    @media (min-width: 740px) {
        width: 70%;
        margin: 97px 0 97px 49px;
    }
`

const ContentSection = styled(Flex)<FlexProps>`
    width: 100%;
    flex-flow: column;  
    padding: 32px 32px;

    .text-link {
        color: ${props => props.theme.colors.text};
        margin-left: 0;
        transition: 0.2s all ease;
        &:hover {
            color: ${props => props.theme.colors.highlightColor};
        }
    }
    .text-link.linkedin {
        margin-top: 16px;
        @media (min-width: 500px) {
            margin-left: 32px;
            margin-top: 0;
        }
    }
    p {
        margin-bottom: 32px;
        &:last-child{
            margin-bottom: 16px;
        }
    }

    @media (min-width: 740px) {
        width: 75%;
        padding: 48px 53px;
    }
`

const Heading = styled(H1)`
    font-size: 19px;
    font-family: Roboto Mono;
    color: ${props => props.theme.colors.colorText};     
`

const TopBlackout = styled(Box)`
    position: absolute;
    background: ${props => props.theme.colors.background};
    top: -32px;
    left: 32px;
    width: 239px;
    height: 32px;
    @media (min-width: 740px) {
        top: -48px;
        left: 48px;
        width: 239px;
        height: 48px;
    }
`
const ImageContainer = styled(Box)`
    position: relative;
    text-align: center;
    padding-bottom: 32px;
    img {
        width: 308px;
    }
    @media (min-width: 740px) {
        position: absolute;
        top: 98px;
        right: -169px;
        padding: 0;
        adding-bottom: 48px;
    }
`

export function About(props) {
  return (
    <main>
    <div className="page-content">
        <div className="grid-background">
            <ContentBackground>
                <TopBlackout />
                <ContentSection>
                    <Heading>Maira Ahmad, UX designer at Google — working on the Youtube Ads formats team.</Heading>
                    <Box mt={4}>
                            <Paragraph>
                            I consider myself to be a full-stack designer, because I have a strong understanding of the full product lifecycle; research, branding, marketing, interaction design, project management, and software engineering. 
                            </Paragraph>
                            <Paragraph>
                            In my previous roles at Software Agencies, I was helping to transform complex business ideas into simple and intuitive digital experiences.  I consulted in various industries from FinTech, HealthTech, E-Commerce, etc helping both startups and enterprises. It was a blast!
                            </Paragraph>
                            <Paragraph>
                            When I’m not working, you can catch me planning a trip, being in awe of Tiktok's algorithm, or more often than not - napping.
                            </Paragraph>
                    </Box>
                    <Flex alignItems="center" mt={3} mb={3} flexWrap="wrap">
                        <ButtonLinkComp link={"https://drive.google.com/file/d/12cu-ShNQb9mG1T7yTRb4G64TmRrxUCf-/view?usp=sharing"} title="View Resume" />
                        <a className="text-link linkedin" rel="noopener noreferrer" href="https://www.linkedin.com/in/mairaahmad/" target="_blank">Connect on Linkedin</a>
                    </Flex>
                </ContentSection>

                <ImageContainer>
                    <Image
                        src={mairaAhmad}
                    />
                </ImageContainer>
            </ContentBackground>
        </div>
        </div>
    </main>
  );
}