import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";

const ButtonLink = styled.a`
  padding: 15px 24px;
  font-size: 16px;
  text-decoration: none;
  background-color: ${props => props.theme.colors.shadow};
  border: solid 1px ${props => props.theme.colors.shadow};
  border-radius: 50px;
  color: ${props => props.theme.colors.text};
  outline: 0;
  box-shadow: none;
  appearance: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: ${props => props.theme.colors.gridHover};
  }
`

export function ButtonLinkComp(props) {
  return <ButtonLink rel="noopener noreferrer" href={props.link} target="_blank">{props.title}</ButtonLink>;
}