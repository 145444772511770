import React, { useState, useEffect } from 'react'
import { Global } from '@emotion/core'
// import { ThemeProvider } from 'emotion-theming'

import { ThemeProvider } from "styled-components";
import ReactGA from 'react-ga';

import { lightTheme, darkTheme } from './styles/settings'
import { GlobalStyles } from './styles/global'
import { Routes, Route, BrowserRouter as Router } from "react-router-dom"; 
import ScrollToTop from "./atoms/scrollToTop";

import { Navigation } from "./molecules/nav";
import { ComingSoon } from './pages/coming-soon'
import { HomeListPage } from './pages/home-list-page'
import { About } from './pages/about'

import { Facebook } from './pages/portfolio/facebook'
import { Science37 } from './pages/portfolio/science37'
import { EMoneyger } from './pages/portfolio/emoneyger'
import { XClaim } from './pages/portfolio/xclaim'
import { MilkJarCookies } from './pages/portfolio/milkjarcookies'

const TRACKING_ID = "G-H0Y9BFXB5Z"
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const [theme, setTheme] = useState(window.localStorage.getItem('theme') || 'light');

  const isDarkTheme = theme === "dark";

  const toggleTheme = () => {
    const updatedTheme = isDarkTheme ? "light" : "dark";
    setTheme(updatedTheme);
    localStorage.setItem("theme", updatedTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    const prefersDark = window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (savedTheme && ["dark", "light"].includes(savedTheme)) {
      setTheme(savedTheme);
    } else if (prefersDark) {
      setTheme("dark");
    }
  }, []);

  return (
      <Router>
        <ScrollToTop /> 
        <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
          <GlobalStyles />
            <Navigation 
              toggleButton={
                <button onClick={toggleTheme} id="theme-toggle">
                    {isDarkTheme ?
                      <span aria-label="Light mode" role="img">🌝</span>
                      :
                      <span aria-label="Dark mode" role="img">🌚</span>
                    }
                  </button>
                }
            />
            
            <Routes>
              {/* <Route element={<HomeListPage />} path="/" caseSensitive={false} />
              <Route element={<Facebook />} path="/facebook" caseSensitive={false} />
              <Route element={<Science37 />} path="/science37" caseSensitive={false} />
              <Route element={<MilkJarCookies />} path="/milkjarcookies" caseSensitive={false} />
              <Route element={<EMoneyger />} path="/emoneyger" caseSensitive={false} />
              <Route element={<XClaim />} path="/xclaim" caseSensitive={false} /> */}
              <Route element={<About />} path="/" caseSensitive={false} />
              {/* <Route element={<About />} path="/about" caseSensitive={false} /> */}
              {/* <Route element={<ComingSoon />} path="/coming-soon" caseSensitive={false} /> */}
            </Routes>
        </ThemeProvider>
      </Router>
    )
  }


  export default App;
